import React from 'react';
import logo from '../assets/img/logo.webp';
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Clock from 'react-live-clock';

const Header = () => {
    const history = useHistory();

    const settings = useSelector(
        state => state.settings
    );

    const logoClick = () => {
        if (navigator.userAgent.indexOf('Mobile/') > 0 ) {
            window.location.href = '//app.hebrewnews.com/';
        }else {
            window.location.href ='//hebrewnews.com/';
        }
    }

    return(
        <div className="">
        <div className="row justify-content-center">
            <div className="col header">
                <div className="row">
                    <div className="col text-center p-2">
                        <img onClick={logoClick} src={logo} style={{width: "300px"}} alt="logo"/>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Header;
